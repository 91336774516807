<template>
  <v-time-picker :value="localeValue"
                 format="24hr"
                 :landscape="$vuetify.breakpoint.mdAndUp"
                 full-width
                 :disabled="isReadSurvey || options.readonly"
                 @input="onUpdateValue"
  />
</template>

<script>
import questionMixins from '@/components/Survey/Reader/questionMixins'

export default {
  name: 'SurveyQuestionTime',

  mixins: [questionMixins],

  computed: {
    localeValue () {
      const hour = Math.floor(this.value / 60)
      const minutes = this.value - (hour * 60)
      return `${hour}:${minutes}`
    },
  },

  methods: {
    onUpdateValue (newValue) {
      const [hour, minutes] = newValue.split(':')
      this.$emit('update-value', parseInt(hour) * 60 + parseInt(minutes))
    },
  },
}
</script>
